import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "chats-list-container" }
const _hoisted_2 = { class: "chats-list-search" }
const _hoisted_3 = { class: "p-input-icon-left" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "chats-list-item-header" }
const _hoisted_7 = { class: "chats-item-header-left" }
const _hoisted_8 = {
  key: 0,
  class: "chat-item-header-right"
}
const _hoisted_9 = { class: "chat-list-item-footer" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("i", {
          class: "icon-search",
          "aria-hidden": "true"
        }, null, -1)),
        _createVNode(_component_InputText, {
          modelValue: _ctx.filterValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterValue) = $event)),
          placeholder: _ctx.$t('chat.filter-text')
        }, null, 8, ["modelValue", "placeholder"])
      ])
    ]),
    (_ctx.chatsFilter && _ctx.chatsFilter.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatsFilter, (chat, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(["chats-list-item", { selected: chat.id == _ctx.selectedChatId }]),
              onClick: ($event: any) => (_ctx.selectChat(chat))
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h1", null, _toDisplayString((chat.oppositeUser.name ? chat.oppositeUser.name : '') +
                ' ' +
                (chat.oppositeUser.surname ? chat.oppositeUser.surname : '')), 1)
                ]),
                (chat.lastMessageDate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.dateFormat.formatDateOnlyHour24h(_ctx.dateFormat.utcToUserTimezone(chat.lastMessageDate.toString()))), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                (chat.lastMessageType === _ctx.MessageType.IMAGE)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                      _cache[2] || (_cache[2] = _createElementVNode("i", {
                        class: "icon-chat-sent",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(chat.userId === chat.lastUserIdSendMessage ? _ctx.$t('chat.image-sent') : _ctx.$t('chat.image-received')), 1)
                    ]))
                  : (
              (chat.lastMessageType === _ctx.MessageType.TEXT || chat.lastMessageType == undefined) && chat.lastMessageValue
            )
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", {
                          class: "icon-chat-sent",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(chat.lastMessageValue), 1)
                      ]))
                    : _createCommentVNode("", true),
                (chat.messagesIdUnreadByUserId && chat.messagesIdUnreadByUserId.length > 0)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 2,
                      src: _ctx.newMessageIconAlert,
                      alt: ""
                    }, null, 8, _hoisted_12))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_5))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          text: _ctx.$t('chat.empty-filter'),
          secondary: false,
          icon: "icon-chat"
        }, null, 8, ["text"]))
  ]))
}