
import { defineComponent, onMounted, Ref, ref } from 'vue';
import AppHeading from '@/components/HeadingComponent.vue';
import ChatList from '@/components/chats/ChatsList.vue';
import ChatMessages from '@/components/chats/ChatMessages.vue';
import { useRoute } from 'vue-router';
import Chat from '@/models/Chat';
import { ServiceError } from '@/services/util/ServiceError';
import { chatService } from '@/services/ChatService';
import Message from '@/models/Message';
import EmptyState from '@/components/EmptyState.vue';

export default defineComponent({
  components: { AppHeading, ChatList, ChatMessages, EmptyState },
  setup() {
    const route = useRoute();
    const avatarPlaceholder = window.location.origin + '/assets/layout/images/avatar.svg';
    const selectedChat: Ref<Chat | undefined> = ref();
    const messages: Ref<Message[]> = ref([]);
    const chats: Ref<Chat[]> = ref([]);

    async function getMessages(chat: Chat) {
      const result = await chatService.findMessages(chat.id);
      if (!(result instanceof ServiceError)) {
        messages.value = result;
      }
    }

    async function markMessagesAsRead(chat: Chat) {
      if (chat.messagesIdUnreadByUserId && chat.messagesIdUnreadByUserId.length > 0) {
        await chatService.markAsRead(chat.messagesIdUnreadByUserId, chat.id);
      }
    }

    onMounted(async () => {
      await getChats();
    });

    async function getChats() {
      const result = await chatService.findChats();
      if (!(result instanceof ServiceError)) {
        chats.value = result;
      }
    }

    const onChatClick = async (chat: Chat) => {
      selectedChat.value = undefined;
      await markMessagesAsRead(chat);
      await getMessages(chat);
      await getChats();
      selectedChat.value = chat;
    };

    return { route, avatarPlaceholder, selectedChat, chats, messages, onChatClick, getChats };
  },
});
