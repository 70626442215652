
import { isImageBeingCropped } from '@/helpers/composables/useFileInputCrop';
import Message, { MessageType } from '@/models/Message';
import { chatService } from '@/services/ChatService';
import { defineComponent, onMounted, PropType, Ref, ref, nextTick } from 'vue';
import { ServiceError } from '@/services/util/ServiceError';
export default defineComponent({
  emits: ['scroll-to-bottom'],
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const iconsRootPath = `${window.location.origin}/assets/icon/`;
    const message: Ref<Message> = ref(props.message);
    const image: Ref<any> = ref();

    onMounted(async () => {
      await getImageFromS3();
    });

    async function getImageFromS3() {
      const response = await chatService.getContentURLFromS3(message.value.chatId, message.value.id);
      if (!(response instanceof ServiceError)) {
        image.value = response;
      }
      await nextTick(() => {
        emit('scroll-to-bottom');
      });
    }

    return {
      iconsRootPath,
      isImageBeingCropped,
      image,
      MessageType,
    };
  },
});
