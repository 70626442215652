import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import Message from '@/models/Message';
import { PropType, computed } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MessageText',
  props: {
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const getMessageWithFormat = computed(() => {
  const regexUrl = /(?:https?:\/\/|www\.)[^\s]+/g;
  const urlsFound = props.message.value.match(regexUrl) || [];
  let contentMessage = props.message.value;

  urlsFound.forEach((url: string) => {
    contentMessage = replaceUrlWithLink(url, contentMessage);
  });

  return getHtmlContent(contentMessage);
});

function replaceUrlWithLink(url: string, textMessage: string) {
  return textMessage.replace(url, `<a class="link" href="${url}" target="_blank" >${url}</a>`);
}

function getHtmlContent(contentMessage: string) {
  return `<p class="text">${contentMessage}</p>`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "c-message__text",
    innerHTML: getMessageWithFormat.value
  }, null, 8, _hoisted_1))
}
}

})