import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_ctx.image)
    ? (_openBlock(), _createBlock(_component_Image, {
        key: 0,
        src: _ctx.image.url,
        imageClass: "c-message__image",
        alt: "",
        preview: ""
      }, null, 8, ["src"]))
    : _createCommentVNode("", true)
}