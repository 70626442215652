
import {
  isImageBeingCropped,
  onFileInputChange,
  loadImage,
  deleteLoadedImages,
} from '@/helpers/composables/useFileInputCrop';
import dateFormat from '@/helpers/DateFormat.helper';
import Chat from '@/models/Chat';
import { ChatDocument } from '@/models/ChatDocument';
import Message, { MessageFormDto, MessageType } from '@/models/Message';
import { chatService } from '@/services/ChatService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { Client } from '@stomp/stompjs';
import moment from 'moment';
import { computed, defineComponent, nextTick, onMounted, PropType, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import MessageText from './MessageText.vue';
import MessageImage from './MessageImage.vue';

export default defineComponent({
  components: {
    MessageText,
    MessageImage,
  },
  emits: ['on-message-received'],
  props: {
    chat: {
      type: Object as PropType<Chat>,
      required: true,
    },
    messages: {
      type: Object as PropType<Message[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    interface Group {
      [date: string]: Message[];
    }
    const modal = ref(false);
    const route = useRoute();
    const storeProfile = useProfileStore();
    const avatarPlaceholder = window.location.origin + '/assets/layout/images/avatar.svg';
    const newMessage = ref('');
    const messagesList: Ref<Message[]> = ref(props.messages);
    let groupsByDate: Ref<Group> = ref({});
    const patientName = computed(() => {
      return (
        (props.chat.oppositeUser.name ? props.chat.oppositeUser.name : '') +
        ' ' +
        (props.chat.oppositeUser.surname ? props.chat.oppositeUser.surname : '')
      );
    });
    const patientEmail = computed(() => props.chat.oppositeUser.email);

    onMounted(async () => {
      await updateGroupsByDate();
      callsocket();
    });

    const scrollToBottom = () => {
      let element = document.querySelector(`.messages-container`);
      if (element) {
        element.scrollIntoView(false);
      }
    };

    let client: Client;
    const sendNewMessage = async () => {
      if (newMessage.value === '') {
        return;
      }
      const dto = new MessageFormDto(newMessage.value);
      const createdMessage = await chatService.sendMessage(props.chat?.id, dto);
      if (!(createdMessage instanceof ServiceError)) {
        messagesList.value.push(createdMessage);
        await updateGroupsByDate();
        cleanNewMessage();
      }
    };

    const cleanNewMessage = () => {
      newMessage.value = '';
    };

    const callsocket = () => {
      const token = storeProfile.authToken;
      client = new Client({
        brokerURL: process.env.VUE_APP_WSS_BASE_URL + `?Authorization=${token}`,
        onConnect: () => {
          client.subscribe('/communication/chat', async (message) => {
            const socketMessage = JSON.parse(message.body);
            const newMessage = socketMessage.object as Message;
            emit('on-message-received');
            if (newMessage.chatId == props.chat.id) {
              messagesList.value.push(newMessage);
              await updateGroupsByDate();
            }
          });
        },
      });

      client.activate();
    };

    const updateGroupsByDate = async () => {
      groupsByDate.value = {};
      messagesList.value.forEach((m) => {
        const date = moment(m.creationDate.toString().split('T')[0]).format(dateFormat.getDateSimpleFormat());
        if (groupsByDate.value[date]) {
          groupsByDate.value[date].push(m);
        } else {
          groupsByDate.value[date] = [m];
        }
      });
      await nextTick(() => {
        scrollToBottom();
      });
    };

    const uploadImage = async () => {
      const dto = new MessageFormDto('', MessageType.IMAGE);
      const messageCreated = await chatService.sendMessage(props.chat.id, dto);

      if (messageCreated instanceof ServiceError) {
        throw new Error('ERR_UPLOAD_IMG');
      }

      if (loadImage.value && !loadImage.value.id) {
        const imageToSend = loadImage.value;
        const newDocument = {
          name: imageToSend.file.name,
          contentType: imageToSend.file.type,
        } as ChatDocument;

        const documentCreated = await chatService.createDocument(props.chat.id, messageCreated.id, newDocument);

        if (documentCreated instanceof ServiceError) {
          throw new Error('ERR_UPLOAD_IMG');
        }
        const s3Url = await chatService.generateURLToUploadToS3(props.chat.id, messageCreated.id);

        if (s3Url instanceof ServiceError) {
          throw new Error('ERR_UPLOAD_IMG');
        }
        const s3Document = await chatService.uploadToS3(s3Url.url, imageToSend.file);
        if (s3Document instanceof ServiceError) {
          throw new Error('ERR_UPLOAD_IMG');
        }

        messagesList.value.push(messageCreated);
        await updateGroupsByDate();
      }

      closeDialog();
      deleteLoadedImages();
    };

    const onFileSelect = (event: any) => {
      onFileInputChange(event);
      openDialog();
    };

    const fileInputRef = ref();
    const deleteLoadImage = () => {
      closeDialog();
      deleteLoadedImages();
    };

    const openFileInput = () => {
      fileInputRef.value?.click();
    };

    const openDialog = () => {
      modal.value = true;
    };

    const closeDialog = () => {
      modal.value = false;
    };

    return {
      route,
      avatarPlaceholder,
      newMessage,
      messagesList,
      groupsByDate,
      sendNewMessage,
      cleanNewMessage,
      callsocket,
      dateFormat,
      patientName,
      patientEmail,
      onFileInputChange,
      loadImage,
      isImageBeingCropped,
      uploadImage,
      deleteLoadImage,
      MessageType,
      modal,
      onFileSelect,
      openFileInput,
      fileInputRef,
      scrollToBottom,
    };
  },
});
