import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-chat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeading = _resolveComponent("AppHeading")!
  const _component_ChatList = _resolveComponent("ChatList")!
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeading, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].parent : '')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ChatList, {
        chatsList: _ctx.chats,
        onSelectChat: _ctx.onChatClick
      }, null, 8, ["chatsList", "onSelectChat"]),
      (_ctx.selectedChat)
        ? (_openBlock(), _createBlock(_component_ChatMessages, {
            key: 0,
            chat: _ctx.selectedChat,
            messages: _ctx.messages,
            onOnMessageReceived: _ctx.getChats
          }, null, 8, ["chat", "messages", "onOnMessageReceived"]))
        : (_ctx.chats.length == 0)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              class: "empty-state",
              heading: _ctx.$t('chat.empty-title'),
              text: _ctx.$t('chat.empty-message'),
              secondary: false,
              icon: "icon-chat"
            }, null, 8, ["heading", "text"]))
          : _createCommentVNode("", true)
    ])
  ], 64))
}