import { ChatDocument } from './ChatDocument';

enum MessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export default interface Message {
  accountId: string;
  chatId: string;
  id: string;
  value: string;
  sender: string;
  receiver: string;
  creationDate: Date;
  type: MessageType;
  document: ChatDocument;
}

class MessageFormDto {
  message: string;
  type: MessageType;

  constructor(message: string, type: MessageType = MessageType.TEXT) {
    this.message = message;
    this.type = type;
  }
}

export { Message, MessageFormDto, MessageType };
