
import Chat from '@/models/Chat';
import { Ref, ref } from '@vue/reactivity';
import { computed, defineComponent, watchEffect } from 'vue';
import EmptyState from '@/components/EmptyState.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { useRoute } from 'vue-router';
import router from '@/router';
import { MessageType } from '@/models/Message';

export default defineComponent({
  components: { EmptyState },
  emits: ['selectChat'],
  props: {
    chatsList: {
      type: Array as () => Chat[],
      required: true,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const newMessageIconAlert = window.location.origin + '/assets/images/new-message-alert.svg';
    const filterValue = ref('');
    const selectedChatId = ref('');

    const chatsFilter: Ref<Chat[]> = computed(() =>
      props.chatsList.filter((chat) => {
        let containsName = false;
        let containsSurname = false;
        if (chat.oppositeUser.name) {
          containsName = chat.oppositeUser.name.toLowerCase().includes(filterValue.value.toLocaleLowerCase());
        }
        if (chat.oppositeUser.surname) {
          containsSurname = chat.oppositeUser.surname.toLowerCase().includes(filterValue.value.toLocaleLowerCase());
        }
        return (filterValue.value && (containsName || containsSurname)) || !filterValue.value;
      }),
    );

    const selectChat = (chat: Chat) => {
      selectedChatId.value = chat.id;
      context.emit('selectChat', chat);
    };

    watchEffect(async () => {
      if (props.chatsList.length > 0 && route.query.chatId) {
        const chatId = Array.isArray(route.query.chatId) ? route.query.chatId[0] : route.query.chatId;
        const chatFound = props.chatsList.find((c) => c.id == chatId);
        if (chatFound) {
          selectChat(chatFound);
        }
        await router.replace({ query: {} });
      }
    });

    return { selectChat, filterValue, chatsFilter, dateFormat, selectedChatId, newMessageIconAlert, MessageType };
  },
});
