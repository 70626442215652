import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-chat__container" }
const _hoisted_2 = { class: "c-chat__header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "c-chat__body" }
const _hoisted_5 = { class: "messages-container" }
const _hoisted_6 = { class: "group-container" }
const _hoisted_7 = { class: "message-container" }
const _hoisted_8 = { class: "date" }
const _hoisted_9 = { class: "message-time" }
const _hoisted_10 = { class: "c-chat__footer" }
const _hoisted_11 = { class: "chat-footer__top" }
const _hoisted_12 = { class: "chat-footer__bottom" }
const _hoisted_13 = {
  key: 0,
  class: "c-dialog__img-wrapper"
}
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageText = _resolveComponent("MessageText")!
  const _component_MessageImage = _resolveComponent("MessageImage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.chat)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.patientName), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", null, _toDisplayString(_ctx.patientEmail), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupsByDate, (group, dateIndex) => {
            return (_openBlock(), _createElementBlock("div", { key: dateIndex }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", null, _toDisplayString(dateIndex), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (message, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: i,
                      class: _normalizeClass(["message-box", { sender: _ctx.chat?.userId === message.sender, receiver: _ctx.chat?.userId === message.receiver }])
                    }, [
                      (message.type == _ctx.MessageType.TEXT || message.type == undefined)
                        ? (_openBlock(), _createBlock(_component_MessageText, {
                            key: 0,
                            message: message
                          }, null, 8, ["message"]))
                        : _createCommentVNode("", true),
                      (message.type == _ctx.MessageType.IMAGE)
                        ? (_openBlock(), _createBlock(_component_MessageImage, {
                            key: 1,
                            message: message,
                            onScrollToBottom: _ctx.scrollToBottom
                          }, null, 8, ["message", "onScrollToBottom"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dateFormat.formatDateOnlyHour24h(_ctx.dateFormat.utcToUserTimezone(message.creationDate.toString()))), 1)
                    ], 2))
                  }), 128))
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _withDirectives((_openBlock(), _createBlock(_component_Button, {
            class: "p-button-rectangle p-button-only-icon button__upload",
            onClick: _ctx.openFileInput
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("i", {
                class: "icon-image",
                "aria-label": 'Subir imagen',
                "aria-hidden": "true"
              }, null, -1)
            ])),
            _: 1
          }, 8, ["onClick"])), [
            [
              _directive_tooltip,
              _ctx.$t('chat.upload-image'),
              void 0,
              { bottom: true }
            ]
          ]),
          _createVNode(_component_Textarea, {
            modelValue: _ctx.newMessage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMessage) = $event)),
            "auto-resize": true,
            placeholder: _ctx.$t('chat.write-message')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("input", {
            ref: "fileInputRef",
            class: "chat-footer-upload__input",
            type: "file",
            accept: "image/*",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args)))
          }, null, 544),
          _createElementVNode("button", {
            class: "p-button p-button-secondary p-button-medium",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cleanNewMessage && _ctx.cleanNewMessage(...args)))
          }, _toDisplayString(_ctx.$t('common.cancel')), 1),
          _createElementVNode("button", {
            class: "p-button p-button-info p-button-medium",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sendNewMessage && _ctx.sendNewMessage(...args)))
          }, _toDisplayString(_ctx.$t('common.send')), 1)
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.modal,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modal) = $event)),
      modal: true,
      header: _ctx.$t('chat.image-detail')
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-button-secondary p-button-medium",
          onClick: _ctx.deleteLoadImage
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.accept'),
          class: "p-button p-button-info p-button-medium",
          onClick: _ctx.uploadImage
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.loadImage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("img", {
                src: _ctx.loadImage.url,
                class: "c-dialog__img",
                alt: ""
              }, null, 8, _hoisted_14)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}